import React, { useEffect, useState } from "react";

import ProviderCard from "./ProviderCard";

import { useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";

export default function MultipleProviders(params) {
  // const windowUrl = window.location.search;
  // const urlSearchParams = new URLSearchParams(windowUrl);

  const { t } = useTranslation();

  const [contentLoaded, setContentLoaded] = useState(false);
  const [myData, setMyData] = useState({});
  const [errCallApi, setErrCallApi] = useState(false);

  if (params.pagination === null) {
    params.pagination += "&per_page=10&page=0";
  }

  const loadApi = () => {
    fetch(
      process.env.GATSBY_REST_API_URL +
        `/providers/search?` +
        params.search +
        params.pagination
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      }) // parse JSON from request
      .then((resultData) => {
        if (resultData.data.length !== 0) {
          setMyData(resultData);
          setErrCallApi(false);
          params.checkResults(false);
        } else {
          params.checkResults(true);
          setErrCallApi(true);
        }
      })
      .catch((err) => {
        params.checkResults(true);
        setErrCallApi(true);
        console.log(err);
      });
  };

  useEffect(() => {
    loadApi();
  }, [params.search]);

  useEffect(() => {
    myData.data && setContentLoaded(true);
  }, [myData]);

  return (
    <React.Fragment>
      <main className="provider-cards">
        {errCallApi && (
          <div style={{ fontSize: "28px", fontWeight: "600", color: "red" }}>
            {t(
              "Oops! We can't find any suppliers in your area right now. But please come back! We are adding new options every day and will surely be able to help you soon."
            )}
          </div>
        )}
        {!errCallApi && myData && myData.data && contentLoaded && (
          <div>
            {myData.data &&
              myData.data.map((provider, index) => {
                return (
                  <ProviderCard
                    key={index}
                    id={provider.id}
                    name={provider.name}
                    address={provider.address}
                    facebook_link={provider.facebook_link}
                    whatsapp_contact={provider.whatsapp_contact}
                    website={provider.website}
                    type_facility={provider.facility_human_name}
                    phone_contact={provider.phone_contact}
                    average={provider.average}
                    hours={provider.hours}
                  />
                );
              })}
          </div>
        )}
      </main>
    </React.Fragment>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
