import React from "react";

// Custom styles
import "./buttons.styles.scss";

export default function CustomButton({
  color,
  type,
  text,
  size,
  iconUrl,
  iconAlt,
  onClick,
}) {
  return (
    <button type={type} className={`btn--${color} ${size}`} onClick={onClick}>
      {iconUrl && <img src={iconUrl} alt={iconAlt}></img>}
      {text}
    </button>
  );
}
