import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {Helmet} from "react-helmet";
// Bootstrap styles
import "bootstrap/dist/css/bootstrap.min.css";

// Bootstrap components
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

// Custom components
import CustomNav from "../components/Nav/CustomNav";
import MainMap from "../components/Maps/MainMap";
import {ProviderBanner, ReviewProviderBanner,} from "../components/Banners/Banners";
import MultipleProviders from "../components/Provider/MultipleProviders";
import MultipleProvidersSecond from "../components/Provider/MultipleProvidersSecond";
import Footer from "../components/Footer/Footer";
import CustomButton from "../components/Buttons/Buttons";

// Custom styles
import "../assets/scss/home.styles.scss";
import axios from "axios";

// Media assets
import searchIconUrl from "../images/search-icon--white.svg";

import { useTranslation, useI18next } from "gatsby-plugin-react-i18next";
import {graphql} from "gatsby";
import {Controller, useForm} from "react-hook-form";
import qs from "query-string";

let latOffset = 0.1;
let lngOffset = -0.3;

const IndexPage = (props) => {
  const { language } = useI18next();
  const [dataServicesAndProducts, setDataServicesAndProducts] = useState([]);

  // Mobile search provider modal
  const [showModal, setShowModal] = useState(false);
  const [fullscreen, setFullscreen] = useState(true);

  const handleModalClose = () => setShowModal(false);
  const handleModalShow = () => setShowModal(true);

  // End of Mobile search provider modal

  useEffect(() => {
    const providerServices = [];
    const productPills = [];
    const dataProviderServices = axios.get(
        process.env.GATSBY_REST_API_URL + `/services`
    );
    dataProviderServices
        .then((response) => {
          let services = response.data;
          if (services != null && services.length > 0) {
            services?.forEach(function (service) {
              providerServices.push({
                id: service.id,
                name: service.name,
                type: "service",
              });
            });
          }
        })
        .catch((errors) => {
          console.error(errors);
        });

    const dataProductPills = axios.get(
        process.env.GATSBY_REST_API_URL + `/products`
    );
    dataProductPills
        .then((response) => {
          let products = response.data;
          if (products != null && products.length > 0) {
            products?.forEach(function (product) {
              productPills.push({
                id: product.id,
                name: product.name,
                type: "product",
              });
            });
          }
        })
        .catch((errors) => {
          console.err(errors);
        });

    axios
        .all([dataProviderServices, dataProductPills])
        .then(
            axios.spread((...responses) => {
              const dataProviderServices = responses[0].data;
              dataProviderServices.forEach((service, index) => {
                service.type = "service";
              });
              const dataProductPills = responses[1].data;
              dataProductPills.forEach((product, index) => {
                product.type = "product";
              });

              const dataAll = dataProviderServices.concat(dataProductPills);
              const structDataAll = dataAll.map((item, index) => {
                console.log(item);

                return {
                  id: item.id,
                  name: item.name,
                  type: item.type,
                };
              });
              setDataServicesAndProducts(structDataAll);
            })
        )
        .catch((errors) => {
          console.error(errors);
        });
  }, []);

  const { t } = useTranslation();

  const { register, reset, watch, handleSubmit, control, setValue } = useForm({
    defaultValues: {
      region: "",
      country: "",
      zip_code: "",
      products: "",
      services: "",
      range: null,
    },
  });

  let firstSectionQuery = "&per_page=5&page=0";
  let secondSectionQuery = "&per_page=5&page=6";
  let disableFirstSection = false;

  useEffect(() => {
    if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent
        )
    ) {
      latOffset = 0;
      lngOffset = 0;
    }

    let url = window.location.href;

    if (url.includes("?")) {
      window.scrollTo(0, 800);
    }
  }, []);

  const [contentLoaded, setContentLoaded] = useState(false);
  const [myData, setMyData] = useState({});

  const [resetRange, setResetRange] = useState(false);
  const [search, setSearch] = useState("");
  const resultsContainer = useRef(null);

  const [resultsProvidersLength, setResultsProvidersLength] = useState(null);
  const [checkFirstReloadHasSearch, setCheckFirstReloadHasSearch] =
      useState(false);

  useEffect(() => {
    // Fix SSR render
    setSearch(window.location.search.replace("?", ""));
    const urlQuery = qs.parseUrl(window.location.href).query;

    if (Object.keys(urlQuery).length !== 0) {
      setCheckFirstReloadHasSearch(true);
    }

    setValue("country", urlQuery.country);
    setValue("region", urlQuery.region);
    setValue("products", urlQuery.products);
    setValue("services", urlQuery.services);
    setValue("zip_code", urlQuery.zip_code);
    setValue("range", {
      minPrice: urlQuery.min_price,
      max_price: urlQuery.max_price,
    });
  }, []);

  function getParams(data) {
    setResetRange(false);
    setCheckFirstReloadHasSearch(false);
    if (data) {
      let searchParams = {
        region: data.region,
        country: data.country,
        zip_code: data.zip_code,
        min_price: data.range?.minPrice,
        max_price: data.range?.maxPrice,
      };

      if (data.products.startsWith("product_")) {
        searchParams.products = data.products.substring(8);
      }

      if (data.products.startsWith("service_")) {
        searchParams.services = data.products.substring(8);
      }

      handleModalClose();
      const queryString = qs.stringify(searchParams);
      window.history.replaceState(
          null,
          null,
          window.location.origin + "?" + queryString
      );
      setSearch(queryString);
      resultsContainer.current.scrollIntoView();
    }
  }
  console.log("SEARCH: ", search);

  function resetForm() {
    reset();
    setResetRange(true);
    setCheckFirstReloadHasSearch(false);
    window.history.replaceState(null, null, window.location.origin);
    setSearch("");
  }

  function checkResults(e) {
    if (e) {
      setResultsProvidersLength(0);
    }
  }

  useEffect(() => {
    console.log(
        process.env.GATSBY_REST_API_URL + `/providers/search?` + search,
        search
    );
    fetch(process.env.GATSBY_REST_API_URL + `/providers/search?` + search)
        .then((response) => response.json()) // parse JSON from request
        .then((resultData) => {
          console.log(resultData);
          setMyData(resultData);
          setResultsProvidersLength(resultData.total);
        });
    setResetRange(false);
    setCheckFirstReloadHasSearch(false);
  }, [search]);

  useEffect(() => {
    myData.data && setContentLoaded(true);
  }, [myData]);

  let providersMarkers = [];
  let map;
  if (myData && myData.data && contentLoaded) {
    myData.data.forEach(function (provider) {
      if (provider.total <= 5) {
        disableFirstSection = true;
        secondSectionQuery = firstSectionQuery;
      }
      providersMarkers.push({
        id: provider.id,
        latitude: parseFloat(provider.latitude),
        longitude: parseFloat(provider.longitude),
        title: provider.name,
      });
    });

    map = myData.data ? (
        <MainMap
            default_lat={
                parseFloat(providersMarkers[0]?.latitude ?? 19.4023263856085) +
                latOffset
            }
            default_lng={
                parseFloat(providersMarkers[0]?.longitude ?? -99.1715720080371) +
                lngOffset
            }
            zoom={11}
            height={"750px"}
            markers={providersMarkers}
            defaultStyle={false}
            showCurrentLocation={true}
            lines={"#f78f2d"}
        />
    ) : (
        ""
    );
  }

  return (
      <>
        <Helmet htmlAttributes={{ lang: language }}>
          <meta charSet="utf-8" />
          {/* <!-- HTML Meta Tags --> */}
          <meta
              name="description"
              content={t(
                  "Directory of Contraceptive Services and Family Planning. Provided by Find My Method to help you find sexual health information and services in your area."
              )}
          />
          <meta name="keywords" content={t("Contraceptive method")} />
          {/* <!-- END of HTML Meta Tags --> */}
          <link
              rel="apple-touch-icon"
              sizes="180x180"
              href="https://findmymethod.org/wp-content/uploads/2019/05//apple-touch-icon.png"
          />
          <link
              rel="icon"
              type="image/png"
              sizes="32x32"
              href="https://findmymethod.org/wp-content/uploads/2019/05//favicon-32x32.png"
          />
          <link
              rel="icon"
              type="image/png"
              sizes="16x16"
              href="https://findmymethod.org/wp-content/uploads/2019/05//favicon-16x16.png"
          />
          <link
              rel="manifest"
              href="https://findmymethod.org/wp-content/uploads/2019/05//site.webmanifest"
          />
          <link
              rel="mask-icon"
              href="https://findmymethod.org/wp-content/uploads/2019/05//safari-pinned-tab.svg"
              color="#5bbad5"
          />
          <link rel="icon" type="image/x-icon" href="/favicon.svg" />
          <meta name="msapplication-TileColor" content="#5bbad5" />
          <meta name="theme-color" content="#ffffff" />
          <title>{t("Find My Method: Contraceptive Services Directory")}</title>
          <link rel="canonical" href={process.env.GATSBY_SITE_URL}/>
        </Helmet>
        <CustomNav theme="dark" />
        <main>
          {map}
          <title>Register Provider</title>
          <section className="hero">
            <CustomButton
                color="orange"
                type="button"
                iconUrl={searchIconUrl}
                text={t("Search a provider")}
                onClick={handleModalShow}
            />
            <Container>
              <Form
                  onSubmit={handleSubmit((data) => {
                    getParams(data);
                  })}
              >
                <Row className="mb-3">
                  <Form.Group as={Col}>
                    <Controller
                        control={control}
                        name="country"
                        render={({ field: { onChange, value } }) => (
                            <>
                              <Form.Label>{t("Country")}</Form.Label>
                              <Form.Select
                                  id="country"
                                  defaultValue="Choose..."
                                  onChange={onChange}
                                  value={value}
                                  style={{ cursor: "pointer" }}
                              >
                                <option value="">{t("Choose...")}</option>
                                <option>{t("Mexico")}</option>
                              </Form.Select>
                            </>
                        )}
                    />
                  </Form.Group>

                    <Form.Group as={Col}>
                        <Controller
                            control={control}
                            name="region"
                            render={({field: {onChange, value}}) => (
                                <>
                                    <Form.Label>{t("Region")}</Form.Label>
                                    <Form.Select
                                        id="region"
                                        defaultValue="Choose..."
                                        onChange={onChange}
                                        value={value}
                                        style={{cursor: "pointer"}}
                                    >
                                        <option value="">{t("Choose...")}</option>
                                        <option value={"Ciudad de México"}>
                                            {t("Mexico City")}
                                        </option>
                                    </Form.Select>
                                </>
                            )}
                        />
                    </Form.Group>
                </Row>
                <Row>
{/*                  <Form.Group as={Col}>
                    <Form.Label>{t("Zip Code")}</Form.Label>
                    <Form.Control id="zip_code" {...register("zip_code")} />
                  </Form.Group>*/}

                  <Form.Group as={Col}>
                    <Controller
                        control={control}
                        name="products"
                        render={({ field: { onChange, value } }) => (
                            <>
                              <Form.Label>{t("Contraceptive method")}</Form.Label>
                              <Form.Select
                                  id="products"
                                  defaultValue="Choose..."
                                  onChange={onChange}
                                  value={value}
                                  style={{ cursor: "pointer" }}
                              >
                                <option id={0} value={0}>
                                  {t("Choose...")}
                                </option>

                                {dataServicesAndProducts.map((item) => (
                                    <option
                                        id={item.id}
                                        value={item.type + "_" + item.id}
                                    >
                                      {t(item.name)}
                                    </option>
                                ))}

                                {/* <option id={1} value={1}>
                            {t("External Condoms")}
                          </option>
                          <option id={2} value={2}>
                            {t("Internal Condoms")}
                          </option>
                          <option id={3} value={3}>
                            {t("Daily contraceptive pill")}
                          </option>
                          <option id={4} value={4}>
                            {t("Contraceptive injection")}
                          </option>
                          <option id={5} value={5}>
                            {t("Patch")}
                          </option>
                          <option id={6} value={6}>
                            {t("Ring")}
                          </option>
                          <option id={7} value={7}>
                            {t("Emergency contraceptive pill")}
                          </option>
                          <option id={8} value={8}>
                            {t("Cervical cap")}
                          </option>
                          <option id={9} value={9}>
                            {t("Diaphragm")}
                          </option>
                          <option id={10} value={10}>
                            {t("Spermicide")}
                          </option>
                          <option id={11} value={11}>
                            {t("Sponge")}
                          </option> */}
                              </Form.Select>
                            </>
                        )}
                    />
                  </Form.Group>
                </Row>
                {/*<Row>
                <Controller
                  control={control}
                  name="range"
                  render={({ field: { onChange } }) => (
                    <MinMaxRange
                      GetDataRange={onChange}
                      isResetForm={resetRange}
                    />
                  )}
                />
              </Row>*/}
                <Button variant="primary" type="submit" id="btn-search-provider">
                  <img src={searchIconUrl} alt="fmm" />
                  {t("Search a provider")}
                </Button>
                {search.length ? (
                    <Button
                        type="button"
                        onClick={resetForm}
                        style={{ background: "#262262" }}
                    >
                      {t("Reset Search")}
                    </Button>
                ) : (
                    <></>
                )}
              </Form>
            </Container>
          </section>

          <div className="resultsContainer" ref={resultsContainer}>
            <ProviderBanner />
            <section className="results">
              <Container>
                <h3>
                  {t("Results based on your preferences")} (
                  {resultsProvidersLength})
                </h3>
                <p>
                  {t(
                      "Find My Method provides content intended for informational purposes only and is not affiliated with a medical organization."
                  )}
                </p>
                {!disableFirstSection ? (
                    <div className="results__wrapper">
                      <MultipleProviders
                          search={search}
                          pagination={firstSectionQuery}
                          checkResults={checkResults}
                      />
                    </div>
                ) : null}
              </Container>
              <ReviewProviderBanner results={myData.providers} />
              <Container>
                <div className="results__wrapper">
                  <MultipleProvidersSecond
                      search={search}
                      checkResults={checkResults}
                      isResetForm={resetRange}
                      checkFirstReloadHasSearch={checkFirstReloadHasSearch}
                      resultsProvidersLength={resultsProvidersLength}
                  />
                </div>
              </Container>
            </section>
          </div>
        </main>
        <Modal show={showModal} fullscreen={fullscreen} onHide={handleModalClose}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <Form
                onSubmit={handleSubmit((data) => {
                  getParams(data);
                })}
            >
              <Row className="mb-3">
                <Form.Group as={Col} xs="12">
                  <Controller
                      control={control}
                      name="country"
                      render={({ field: { onChange, value } }) => (
                          <>
                            <Form.Label>{t("Country")}</Form.Label>
                            <Form.Select
                                id="country"
                                defaultValue="Choose..."
                                onChange={onChange}
                                value={value}
                                style={{ cursor: "pointer" }}
                            >
                              <option value="">{t("Choose...")}</option>
                              <option>{t("Mexico")}</option>
                            </Form.Select>
                          </>
                      )}
                  />
                </Form.Group>

                  {<Form.Group as={Col} xs="12" className="mt-3">
                      <Controller
                          control={control}
                          name="region"
                          render={({field: {onChange, value}}) => (
                              <>
                                  <Form.Label>{t("Region")}</Form.Label>
                                  <Form.Select
                                      id="region"
                                      defaultValue="Choose..."
                                      onChange={onChange}
                                      value={value}
                                      style={{cursor: "pointer"}}
                                  >
                                      <option value="">{t("Choose...")}</option>
                                      <option value={"Ciudad de México"}>
                                          {t("Mexico City")}
                                      </option>
                                  </Form.Select>
                              </>
                          )}
                      />
                  </Form.Group>}
              </Row>
              <Row>
{/*                <Form.Group as={Col} xs="12">
                  <Form.Label>{t("Zip Code")}</Form.Label>
                  <Form.Control id="zip_code" {...register("zip_code")} />
                </Form.Group>*/}

                <Form.Group as={Col} xs="12" className="mt-3">
                  <Controller
                      control={control}
                      name="products"
                      render={({ field: { onChange, value } }) => (
                          <>
                            <Form.Label>{t("Contraceptive method")}</Form.Label>
                            <Form.Select
                                id="products"
                                defaultValue="Choose..."
                                onChange={onChange}
                                value={value}
                                style={{ cursor: "pointer" }}
                            >
                              <option id={0} value={0}>
                                {t("Choose...")}
                              </option>

                                {dataServicesAndProducts.map((item) => (
                                    <option
                                        id={item.id}
                                        value={item.type + "_" + item.id}
                                    >
                                        {t(item.name)}
                                    </option>
                                ))}
                            </Form.Select>
                          </>
                      )}
                  />
                </Form.Group>
              </Row>
{/*              <Row className="ps-3 pe-4 mt-3">
                <Controller
                    control={control}
                    name="range"
                    render={({ field: { onChange } }) => (
                        <MinMaxRange
                            GetDataRange={onChange}
                            isResetForm={resetRange}
                        />
                    )}
                />
              </Row>*/}
              <Button variant="primary" type="submit">
                <img src={searchIconUrl} alt="fmm" />
                {t("Search a provider")}
              </Button>
              {search.length ? (
                  <Button
                      type="button"
                      onClick={resetForm}
                      style={{ background: "#262262" }}
                  >
                    {t("Reset Search")}
                  </Button>
              ) : (
                  <></>
              )}
            </Form>
          </Modal.Body>
        </Modal>
        <Footer />
      </>
  );
};
export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
