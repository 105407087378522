import React from "react";
import { Link } from "gatsby";

// Bootstrap components
import Container from "react-bootstrap/Container";

// Custom components
// Custom styles
import "./providerCard.styles.scss";

// Media assets
import fbIconUrl from "../../images/icon-social-2.svg";
import waIconUrl from "../../images/icon-social-1.svg";
import socialIconUrl from "../../images/icon-socialWorld.svg";
import pharmacyIconUrl from "../../images/icon-pharmacy.svg";
import clockIconUrl from "../../images/clock.svg";
import pinIconUrl from "../../images/icon-pin.svg";
import phoneIconUrl from "../../images/icon-phone.svg";
import shareIconUrl from "../../images/share--white.svg";
import RatingViewHeart from "../Raiting/RaitingViewHeart";
import FacilityIcon from "../Facility/FacilityTypeIcon";

import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import { useTranslation } from "gatsby-plugin-react-i18next";

const share = (id) => {
  let socialButtons = document.getElementById("shareIcons_" + id);
  if (socialButtons !== undefined && socialButtons?.style != null) {
    if (socialButtons.style.display === "none") {
      socialButtons.style.display = "block";
    } else {
      socialButtons.style.display = "none";
    }
  }
};

export default function ProviderCard(params) {
  const { t } = useTranslation();

  const shareData = {
    title: params.name,
    text: "Checkout this provider I've just found on Find My Method",
    url: process.env.GATSBY_SITE_URL + `/provider/${params.id}`,
  };

  return (
    <div className="provider-card">
      <Container>
        <div className="provider-card__heading">
          <h4 >
            <Link className="provider-card__heading__name" to={`provider/${params.id}`}>{params.name}</Link>
          </h4>
          <div className="provider-card__heading__social">
            <a href={params.facebook_link} target="_blank">
              <img src={fbIconUrl} alt="Contraceptive Services" />
            </a>
            <a
              href={`https://wa.me/${params.whatsapp_contact}`}
              target="_blank"
            >
              <img src={waIconUrl} alt="Contraceptive Services" />
            </a>
            <a href={params.website} target="_blank">
              <img src={socialIconUrl} alt="Contraceptive Services" />
            </a>
          </div>
        </div>
        <div className="provider-card__type">
          <div>
            {FacilityIcon(params?.type_facility)}
            {/* <img src={pharmacyIconUrl} alt="Pharmacy" /> */}
            <p>{t(params.type_facility)}</p>
          </div>
          <div>
            <img src={clockIconUrl} alt="Contraceptive Services" />
            <p>{params.hours ?? t("Not Available")}</p>
          </div>
        </div>
        <div className="provider-card__info">
          <a
            href={`https://www.google.com/maps/search/?api=1&query=${params.address}`}
            target="_blank"
          >
            <img src={pinIconUrl} alt="Contraceptive Services" />
            {params.address}
          </a>
          <a href={`tel:${params.phone_contact}`}>
            <img src={phoneIconUrl} alt="Contraceptive Services" />
            {params.phone_contact}
          </a>
        </div>
        <div className="provider-card__footer">
          <div className="provider-card__footer__reviews">
            <div className="provider-card__footer__reviews__rating">
              <RatingViewHeart val={params.average ?? 5} />
            </div>
            <Link to={`provider/${params.id}`}>{t("Read reviews here")}</Link>
          </div>
          <div className="provider-card__footer__reviews__share">
            <a href={`/review?id=${params?.id}`}>{t("Score your facility")}</a>
            <a onClick={() => share(params?.id)}>
              <img src={shareIconUrl} alt="fmm" />
            </a>
          </div>
          <div
            className={"provider-card__footer"}
            style={{ display: "none", width: "auto" }}
            id={"shareIcons_" + params?.id}
          >
            <FacebookShareButton quote={shareData.text} url={shareData.url}>
              <FacebookIcon size={32} round={true} />
            </FacebookShareButton>
            <TwitterShareButton title={shareData.title} url={shareData.url}>
              <TwitterIcon size={32} round={true} />
            </TwitterShareButton>
            <WhatsappShareButton title={shareData.title} url={shareData.url}>
              <WhatsappIcon size={32} round={true} />
            </WhatsappShareButton>
          </div>
        </div>
      </Container>
    </div>
  );
}
