import React from "react";
import { Link } from "gatsby";

// Bootstrap components
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// Custom styles
import "./banners.styles.scss";

// Custom components
import Swal from "sweetalert2";
import { useTranslation } from "gatsby-plugin-react-i18next";

export function ProviderBanner() {
  const { t } = useTranslation();
  const h2Style = {
    color: 'white'
  };

  return (
    <div className="banner--provider">
      <Container>
        <Row>
          <Col
            xs={12}
            lg={8}
            className="d-flex flex-column justify-content-center"
          >
            <h1>{t("Contraceptive Services Directory")}</h1>
            <h2 style={h2Style}>{t("Are you a provider?")}</h2>
            <p>
              {t("Are you a provider?")}{" "}
              {t(
                "Find My Method wants to close the gap between consumers and providers."
              )}
            </p>
          </Col>
          <Col xs={12} lg={4}>
            {/* <Button color="orange" text="Register your facility" size="auto" /> */}
            <Link to="/provider" className="btn--orange">
              {t("Register your facility")}
            </Link>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

async function chooseProvider() {

  const { value: fruit } = await Swal.fire({
    title: "Select a provider",
    input: "select",
    inputPlaceholder: "Select a Provider",
    showCancelButton: true,
    inputValidator: (value) => {
      return new Promise((resolve) => {
        console.log(value);
        window.location.href =
          process.env.GATSBY_SITE_URL + `/review/provider/${value}`;
      });
    },
  });

  if (fruit) {
    Swal.fire(`You selected: ${fruit}`);
  }
}

export function ReviewProviderBanner({ results }) {
  const { t } = useTranslation();

  if (results === undefined || results.length === 0) {
    return <div />;
  }

  return (
    <div className="banner--review">
      <Container>
        <Row>
          <Col xs={12}>
            <h3>{t("Have you tried any of these providers?")}</h3>
            <a onClick={chooseProvider} className="btn--blue">
              {t("Write a review!")}
            </a>
            <p style={{ color: "white" }}>{t("It’s 100% anonymous")}</p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
