import React, { useEffect, useState, useRef } from "react";

import ProviderCard from "./ProviderCard";

import { useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";

import loadingIcon from "../../images/loading.gif";

function MultipleProvidersSecond(params) {
  // const windowUrl = window.location.search;
  // const urlSearchParams = new URLSearchParams(windowUrl);
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [errCallApi, setErrCallApi] = useState(false);
  const [data, setData] = useState([]);
  const [offset, setOffset] = useState(5);
  const [isMoreApi, setIsMoreApi] = useState(true);

  const loadMoreApi = () => {
    if (isMoreApi && !isLoading) {
      setIsLoading(true);
      fetch(
        process.env.GATSBY_REST_API_URL +
          `/providers/search?` +
          params.search +
          `&per_page=5&page=${offset}`
      )
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error("Something went wrong");
          }
        })
        .then((resultData) => {
          if (resultData.data.length !== 0) {
            setData((prev) => [...prev, ...resultData.data]);
            handleSetOffset(resultData.data.length);
            params.checkResults(false);
          } else {
            params.checkResults(true);
            setErrCallApi(false);
            setData([]);
          }
        })
        .catch((err) => {
          params.checkResults(true);
          setErrCallApi(true);
          setData([]);
          console.log(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  function handleSetOffset(apiLength) {
    if (apiLength < 5) {
      setIsMoreApi(false);
    } else {
      setOffset((prev) => prev + 5);
    }
  }

  useEffect(() => {
    if (params.search) {
      setOffset(5);
      setErrCallApi(false);
      setIsMoreApi(true);
      setData([]);
      loadMoreApi();
    }
  }, [params.search]);

  useEffect(() => {
    if (errCallApi) {
      setIsMoreApi(false);
    }
  }, [errCallApi]);

  useEffect(() => {
    if (params.isResetForm) {
      setOffset(5);
      setErrCallApi(false);
      setIsMoreApi(true);
      setData([]);
    }
  }, [params.isResetForm]);

  function handleScroll(e) {
    if (
      window.innerHeight + e.target.documentElement.scrollTop + 1 >=
        e.target.documentElement.scrollHeight &&
      data.length + 5 < params.resultsProvidersLength
    ) {
      loadMoreApi();
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return (
    <React.Fragment>
      <main className="provider-cards">
        {errCallApi && (
          <div style={{ fontSize: "28px", fontWeight: "600", color: "red" }}>
            {t(
              "Oops! We can't find any suppliers in your area right now. But please come back! We are adding new options every day and will surely be able to help you soon."
            )}
          </div>
        )}
        {!errCallApi && data && (
          <div>
            {data &&
              data.map((provider, index) => {
                return (
                  <ProviderCard
                    key={index}
                    id={provider.id}
                    name={provider.name}
                    address={provider.address}
                    facebook_link={provider.facebook_link}
                    whatsapp_contact={provider.whatsapp_contact}
                    website={provider.website}
                    type_facility={provider.facility_human_name}
                    phone_contact={provider.phone_contact}
                    average={provider.average}
                    hours={provider.hours}
                  />
                );
              })}
          </div>
        )}
        {isLoading && (
          <div
            style={{
              fontSize: "22px",
              fontWeight: "600",
              marginTop: "10px",
              textAlign: "center",
            }}
          >
            <img src={loadingIcon} alt={"Loading"} />
          </div>
        )}
      </main>
    </React.Fragment>
  );
}

export default React.memo(MultipleProvidersSecond);

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
